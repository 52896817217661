<template>
    <v-container fluid>
    <h1 class="mb-3">Log</h1>

    <v-data-table
      dense
      :headers="log_headers"
      :items="log_items"
      item-key="name"
      class="elevation-1"
    ></v-data-table>

    </v-container>
</template>

<script>
export default {
  data() {
    return {
      log_items: [{name: "asdf", payload: "pl"}],
      log_headers: [
       { text: 'Time', value: 'time'},
       { text: 'Type', value: 'type'},
       { text: 'Payload', value: 'payload'},
      ]
    }
  },
  mounted: function() {
    window.api.get("/log")
    .then(res => {
      this.log_items = res.data;
    })
    .catch(err => {
      alert(err)
    })
  }
}
</script>
